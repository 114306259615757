
import { Component, Vue, Watch } from "vue-property-decorator"
import CoreTeamDashboard from "./StaffHome.vue"
import AppPage from "@/components/layout/AppPage.vue"
import {
  ApplyForMissionStaffingPositionMutation,
  ApplyForMissionStaffingPositionMutationMutation,
  ListMissionStaffingPositionFragmentFragment,
  MissionStaffingApplicationFragmentFragment,
  ConsultantOpenOpportunitiesQuery,
  ConsultantOpenOpportunitiesQueryQuery,
  ConsultantOpenOpportunitiesQueryQueryVariables,
  ConsultantApplicationsQuery,
  ConsultantApplicationsQueryQuery,
  ConsultantApplicationsQueryQueryVariables,
  MissionLifecycle,
} from "@/gql"
import RecordSummaryCard from "@/components/content/RecordSummaryCard.vue"
import RecordSummaryCardGroup from "@/components/content/RecordSummaryCardGroup.vue"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import BasicInfoField from "@/components/consultant/BasicInfoField.vue"
import Loader from "@/components/widgets/common/Loader.vue"
import FilterBar, { FilterType } from "@/components/widgets/common/FilterBar.vue"
import { JobFunctions } from "@/constants"
import moment from "moment"
import Pagination from "@/components/widgets/common/Pagination.vue"
import { useCountry } from "@/hooks/useCountry"

enum TabContent {
  Opportunities,
  Applications,
}

interface IActiveStaffingPosition {
  type: string
  data: ListMissionStaffingPositionFragmentFragment
  status?: string
  rejectReason?: string
}

@Component({
  components: {
    AppPage,
    CoreTeamDashboard,
    RecordSummaryCard,
    BasicInfoField,
    Loader,
    RecordSummaryCardGroup,
    FeedBackMessage,
    Pagination,
  },
})
export default class Opportunities extends Vue {
  tab = 0

  showAppPage = true
  errorLoading = false
  networkError = false

  filter: FilterType = {}
  applicationsPagination = { page: 1, per: 10, length: 1 }
  opportunitiesPagination = { page: 1, per: 10, length: 1 }

  applicationsPageCount = 0
  opportunitiesPageCount = 0
  openPositionsCount = 0
  applicationsCount = 0

  loadingOpportunity = false
  loadingApplication = false
  showConfirmModal = false
  applying = false
  expressionOfInterest = ""

  openOpportunities: ListMissionStaffingPositionFragmentFragment[] = []
  consultantStaffingApplications: MissionStaffingApplicationFragmentFragment[] = []
  activeStaffingPosition: IActiveStaffingPosition | null = null
  selectedOpportunity: ListMissionStaffingPositionFragmentFragment | null = null
  selectedApplication: MissionStaffingApplicationFragmentFragment | null = null
  hasOpenUpdates = false
  hasApplicationUpdates = false

  mounted() {
    this.getMissionOpportunities()
  }

  @Watch("tab")
  onTabChange() {
    if (this.filter && (this.filter.search || this.filter.createdAtDate)) {
      this.resetFilter()
    }

    this.fetchData()
  }

  resetFilter() {
    this.$refs.filterBar && (this.$refs.filterBar as FilterBar).reset()
  }

  @Watch("filter")
  onFilterChange() {
    this.debounceCall(() => {
      this.fetchData()
    })
  }

  fetchData() {
    if (this.tab === TabContent.Opportunities) {
      this.getMissionOpportunities()
      this.selectFirstOpportunity()
      return
    }
    if (this.tab === TabContent.Applications) {
      this.getApplications()
      this.selectFirstApplication()
      return
    }
  }

  selectFirstOpportunity() {
    if (this.openOpportunities && this.openOpportunities.length) {
      this.selectedOpportunity = this.openOpportunities[0]
      this.activeStaffingPosition = { type: "open", data: this.openOpportunities[0] }
    }
  }

  selectFirstApplication() {
    this.selectedApplication = this.consultantStaffingApplications[0]
    if (this.selectedApplication && this.selectedApplication.missionStaffingPosition) {
      this.activeStaffingPosition = {
        type: "applied",
        data: this!.selectedApplication!
          .missionStaffingPosition as ListMissionStaffingPositionFragmentFragment,
        status: this.selectedApplication.state as string,
        rejectReason: this.selectedApplication.rejectReason as string,
      }
    }
  }

  async fetch() {
    this.showAppPage = true
    this.errorLoading = false
    this.fetchData()
  }

  async getMissionOpportunities() {
    this.openPositionsCount = 0
    this.opportunitiesPageCount = 0
    try {
      this.loadingOpportunity = true

      const result = await this.$apollo.query<
        ConsultantOpenOpportunitiesQueryQuery,
        ConsultantOpenOpportunitiesQueryQueryVariables
      >({
        query: ConsultantOpenOpportunitiesQuery,
        variables: {
          paginateOpen: {
            page: this.opportunitiesPagination.page,
            per: this.opportunitiesPagination.per,
          },
          openPositionsFilter: { ...this.queryFilter },
        },
      })
      if (result && result.data) {
        this.openOpportunities = result.data.openMissionStaffingPositions.data

        this.openPositionsCount = result.data.openMissionStaffingPositions.pagination.totalSize

        this.opportunitiesPageCount +=
          result.data.openMissionStaffingPositions.pagination.pageCount || 0
      }
      if (result && result.errors) {
        this.showAppPage = false
        this.errorLoading = true
      }
    } catch (error) {
      this.showAppPage = false
      this.networkError = true
    } finally {
      this.loadingOpportunity = false
      this.selectFirstOpportunity()
    }
  }

  async getApplications() {
    this.applicationsCount = 0
    this.applicationsPageCount = 0

    try {
      this.loadingApplication = true
      const result = await this.$apollo.query<
        ConsultantApplicationsQueryQuery,
        ConsultantApplicationsQueryQueryVariables
      >({
        query: ConsultantApplicationsQuery,
        variables: {
          paginateApplications: {
            page: this.applicationsPagination.page,
            per: this.applicationsPagination.per,
          },
          applicationsFilter: { ...this.queryFilter },
        },
      })

      if (result && result.data) {
        if (
          result.data.consultantProfile &&
          result.data.consultantProfile.missionStaffingApplications
        ) {
          this.consultantStaffingApplications =
            result.data.consultantProfile.missionStaffingApplications.data

          this.applicationsCount =
            result.data.consultantProfile?.missionStaffingApplications.pagination.totalSize

          this.applicationsPageCount +=
            result.data.consultantProfile?.missionStaffingApplications.pagination.pageCount || 0
        }
      }
      if (result && result.errors) {
        this.showAppPage = false
        this.errorLoading = true
      }
    } catch (error) {
      this.showAppPage = false
      this.networkError = true
    } finally {
      this.loadingApplication = false
      this.selectFirstApplication()
    }
  }

  get queryFilter() {
    return {
      search: this.filter.search,
      createdAtDate: this.filter.createdAtDate,
      id: this.$route.query.id as string,
    }
  }

  paginationApplicationLength() {
    this.applicationsPagination.length =
      Math.round(this.applicationsCount / this.applicationsPagination.per) > 0
        ? Math.round(this.applicationsCount / this.applicationsPagination.per)
        : 1
  }

  paginationOpenLength() {
    this.opportunitiesPagination.length =
      Math.round(this.openPositionsCount / this.opportunitiesPagination.per) > 0
        ? Math.round(this.openPositionsCount / this.opportunitiesPagination.per)
        : 1
  }

  setActiveOpportunity(position: ListMissionStaffingPositionFragmentFragment) {
    this.selectedOpportunity = position
    this.activeStaffingPosition = Object.assign({}, this.activeStaffingPosition, {
      type: "open",
      data: this.selectedOpportunity,
    })
  }

  setActiveApplication(position: MissionStaffingApplicationFragmentFragment) {
    this.selectedApplication = position
    this.activeStaffingPosition = Object.assign({}, this.activeStaffingPosition, {
      type: "applied",
      data: this.selectedApplication.missionStaffingPosition,
      status: this.selectedApplication.state,
      rejectReason: this.selectedApplication.rejectReason as string,
    })
  }

  async onApplyForStaffingOpportunity() {
    if (!this.activeStaffingPosition) return

    this.applying = true
    await this.mutate<ApplyForMissionStaffingPositionMutationMutation>({
      mutation: ApplyForMissionStaffingPositionMutation,
      variables: {
        id: this.activeStaffingPosition.data.id,
        expressionOfInterest: this.expressionOfInterest,
      },
      done: () => {
        this.applying = false
        this.getApplications()
      },
      success: () => {
        this.addSuccess("Application submitted successfully!")
        this.openOpportunities = this.openOpportunities.filter((opportunity) => {
          return opportunity.id !== this.activeStaffingPosition!.data.id
        })
        this.showConfirmModal = false
        this.activeStaffingPosition!.type = "applied"
      },
      refetchQueries: [
        {
          query: ConsultantOpenOpportunitiesQuery,
          variables: this.opportunitiesQueryVariables,
        },
        {
          query: ConsultantApplicationsQuery,
          variables: this.applicationsQueryVariables,
        },
      ],
    })
  }

  get opportunitiesQueryVariables() {
    return {
      paginateApplications: {
        page: this.opportunitiesPagination.page,
        per: this.opportunitiesPagination.per,
      },
      applicationsFilter: { ...this.queryFilter },
    }
  }

  get applicationsQueryVariables() {
    return {
      paginateApplications: {
        page: this.applicationsPagination.page,
        per: this.applicationsPagination.per,
      },
      applicationsFilter: { ...this.queryFilter },
    }
  }

  async refreshApplications() {
    this.getApplications()
    this.hasApplicationUpdates = false
  }

  @Watch("applicationsPagination.page")
  onApplicationPageChange() {
    this.getApplications()
  }

  @Watch("applicationsPagination.per")
  onPerChange() {
    this.paginationApplicationLength()
    this.getApplications()
  }

  @Watch("opportunitiesPagination.page")
  onOpportunitiesPageChange() {
    this.getMissionOpportunities()
  }

  @Watch("opportunitiesPagination.per")
  onOpenPerChange() {
    this.paginationOpenLength()
    this.getMissionOpportunities()
  }

  timeAgo(date: Date | string) {
    return moment(date).fromNow()
  }
  getJobFunctions(values: any) {
    return (
      JobFunctions.filter((status) => values.includes(status.value))
        .map((func) => func.text)
        ?.join(" , ") || ""
    )
  }
  getStateColor(state: string) {
    // return state === "open" ? "green" : "red"
    if (state === "Open") {
      return "#179717"
    }

    if (state === "Close") {
      return "#ff0008"
    }
  }
  getStateColorIcons(state: string) {
    if (state === "Open") {
      return "#179717"
    }
    if (state === "Close") {
      return "#ff0008"
    }
  }

  getSelectTagGenIcons(missionLifecycle: MissionLifecycle, publishedAt: string, status: string) {
    const tags = [
      {
        name: missionLifecycle.scopeService?.serviceGroup.name,
        icon: "la-briefcase",
      },
      {
        name: missionLifecycle?.projectLocationType,
        icon: "la-map-marker",
      },
      {
        name: "Published: " + moment(publishedAt).fromNow(),
        icon: "la-calendar",
      },
      {
        name: this.getStatus(status),
        icon: "la-edit",
      },
    ]
    return tags
  }

  getCountry(code?: string | null) {
    if (!code) return null

    const { getCountryByCode } = useCountry()
    return getCountryByCode(code)
  }

  getTagGenIcons(
    mission: Pick<
      MissionLifecycle,
      "scopeService" | "projectLocationCountryCode" | "projectLocationType"
    >,
    publishedAt: string,
    type: string
  ) {
    return [
      {
        name: mission?.scopeService?.serviceGroup?.name,
        icon: "la-briefcase",
      },
      {
        name: this.getCountry(mission?.projectLocationCountryCode)?.en,
        icon: "la-map-marker",
      },
      {
        name:
          type === "applied"
            ? "Applied: " + moment(publishedAt).format("Do MMMM, YYYY")
            : "Published: " + moment(publishedAt).format("Do MMMM, YYYY"),
        icon: "la-calendar",
      },
    ]
  }

  getStatus(name: string) {
    if (!name) return "Close"
    const data: Record<string, string> = { published: "Open", pending: "Pending" }
    if (!data[name]) return "Close"
    return data[name]
  }
}
